<template>
  <div>
    <div v-if="! loading">
      <h4>Add Course To  Section #{{ section.sort }} {{ section.title }}</h4>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Course Title</label>
          <input
            type="text"
            placeholder="Course Title"
            v-model="course.title"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Sort Order</label>
          <input
            type="text"
            placeholder="Sort Order"
            v-model="course.sort"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>About this course</label>
          <editor
            api-key="no-api-key"
            v-model="course.about"
            :init="{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
            }"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      course: { about: 'test course' },
      section: {},
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios
        .get(`/api/v1/admin/academy/sections/${this.$route.params.id}`)
        .then((response) => {
          this.section = response.data.section;
          this.loading = false;
        });
    },

    submit() {
      this.loading = true

      this.$axios
        .post(`/api/v1/admin/academy/${this.$route.params.id}/courses`, this.course)
        .then((response) => {
          this.loading = false
          this.$router.push({
            name: 'admin.academy.courses.show',
            params: { id: this.$route.params.id, course_id: response.data.id }
          });
        });
    },
  },
};
</script>
